import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import { VueJsonp } from 'vue-jsonp'
Vue.use(VueJsonp)

// import qs from 'qs'
// Vue.prototype.$qs = qs
// import  VueJsonp  from  'vue-jsonp'

// Vue.use(VueJsonp)
// import Video from 'video.js'
// import 'video.js/dist/video-js.css'

// Vue.prototype.$video = Video
// import axios from 'axios'
// Vue.prototype.$axios = axios
// axios.defaults.baseURL = '/api'


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
