<template>
  <div id="app">
    <div>
      <el-container>
        <el-input
          class="input1"
          placeholder="请输入链接"
          v-model="input"
          clearable
          id="p"
        >
        </el-input>
        <el-button @click="inputj">el-button</el-button>
      </el-container>
    </div>
    <div>
      <video type="video/mp4" :src="src" v-if="h" controls></video>
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import Axios from 'axios'
// import qs from "qs"
// const jsonp = require("jsonp");
// import { jsonp } from "vue-jsonp";

export default {
  name: "app",
  components: {
    // HelloWorld
  },
  data() {
    return {
      input: "",
      src:
        "https://aweme.snssdk.com/aweme/v1/play/?video_id=v0300fea0000bt9l2uaa6r2vks6u4ad0&ratio=720p&line=0",
      h: false,
    };
  },
  created() {
    this.getJson()
  },
  mounted() {
    window.jsonpCallback = (data) => {
      console.log(data.token);
      this.getToken(data.token);
    };
  },
  methods: {
    inputj: function () {
      this.h = true;
      //   // this.src = this.input;
      var url = "https://cn.bing.com/HPImageArchive.aspx?format=js&idx=0&n=1";
      this.$jsonp(url, {
        myCustomUrlParam: "veryNice",
      }).then((json) => {
        console.log(json);
      });
      
    },
    getJson(){
      // this
    }
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: rgb(149, 0, 179);
  margin-top: 60px;
}
#p {
  border-spacing: 0;
  border-collapse: collapse;
  font: inherit;
  border: 0;
  padding: 0 10px 0 0;
  max-height: 30px;
  outline: none;
  border-color: #ccc;
  box-sizing: content-box;
  position: relative;
  height: 40px;
  font-size: 16px;
  background-color: #fff;
  margin: 6px 1px 0 10px;
  width: 486px;
  border-right: 0;
  padding-right: 4px;
}
</style>
